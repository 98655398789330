/*Header*/

.header{
    padding-top: 24px;
    padding-bottom: 48px;
    margin-bottom: 96px;
    background-color: rgba(255, 255, 255, 0%);
    filter: drop-shadow(0px 4px 5px rgba(0, 0, 0, 0.05)) drop-shadow(0px 10px 20px rgba(0, 0, 0, 0.1));
  }

  .header-light{
    background-color: white;
    transition: background-color 0.3s, color 0.3s;
  }

  .header-dark{
    background-color: #151525;;
    transition: background-color 0.3s, color 0.3s;
  }
  
  .header--wrapper{
    flex-wrap: wrap-reverse !important;
  }
  
  .header_job_description{
    padding-bottom: 1rem;
  }

  .header_image--container{
    width: 250px;
    height: 250px; 
    overflow: hidden;
  }
  
  .header_image{
    object-fit: cover; /* Ensures the image doesn't stretch */
    width: 100%;
    height: 100%;
    border-radius: 60%; /* Makes the image circular */
  }

  /* ThemeToggle.css */

.theme-toggle{
  justify-content: flex-start;
}

.toggle-wrap{
  margin: 0 auto;
}

.theme-toggle-button {
  position: relative;
  width: 60px;
  height: 30px;
  background: var(--background-color);
  border: none;
  border-radius: 30px;
  padding: 2px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: background 0.3s;
  margin-bottom: 48px;
}

.theme-toggle-icon {
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.theme-toggle-slider {
  position: absolute;
  top: 3px;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  transition: left 0.3s, background 0.3s;
  background: #f3f3f3;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}



  @media only screen and (max-width: 900px) {

    .header_image--container{
      width: 180px;
      height: 180px;
      overflow: hidden;
    }
      
  }
