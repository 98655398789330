.research_title{
    padding-bottom: 1rem;
}

.research_text{
    padding-bottom: 2rem;
}

/*Research Items*/

.research_item--wrapper{
    display: flex;
    flex-wrap: wrap-reverse;
    padding-bottom: 4rem;
    gap:32px;
}

.research_title{
    padding-bottom: 1rem;
}

.research_body{
    padding-bottom: 1rem;
}

.research_image--container{
    max-height: 300px;

}

.research_image{
    width: 100%;
    height: 100%;
    object-fit: cover;
}